@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;900&display=swap');

svg#Layer_1 {
  width: 100%;
}

.cls-2 {
  margin: 0 auto;
}

.cls-2 {
  fill: #f05a28;
  animation: changeColor 2s linear infinite;
}

@keyframes changeColor {

  0%,
  100% {
    fill: #f05a28;
    /* Start with the 3rd path */
  }

  33.33% {
    fill: #fff;
    /* Change to white for the 1st path */
  }

  66.66% {
    fill: #fff;
    /* Continue with white for the 2nd path */
  }
}

@media (max-width: 767px) {
  .swap-svg {
    max-width: 50px;
    margin: 0 auto;
  }
}